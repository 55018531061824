<template>
  <div class="login-flow-tpl" :class="{ compact }" :data-test-id="elementTest">
    <div class="inner-container" :class="{ 'u-p-less': compact }" :data-test-id="elementTest + '-container'">
      <div class="login-content" :data-test-id="elementTest + '-content'">
        <div class="login-content-wrapper" :data-test-id="elementTest + '-wrapper'">
          <div class="login-popup no-bg no-padding" :data-test-id="elementTest + '-popup'">
            <div class="login-title-wrapper" :data-test-id="elementTest + '-title-wrapper'">
              <button :data-test-id="elementTest + '-back-button'">
                <div
                  class="icon-arrow-left u-bg-steel-grey"
                  :data-test-id="elementTest + '-back-button-icon'"
                  @click="compact ? $emit('go-back') : $router.back()"
                />
              </button>
              <h1 :data-test-id="elementTest + '-title'">{{ $t('setPassword.setPassword') }}</h1>
            </div>
            <p class="login-subtitle" :data-test-id="elementTest + '-subtitle'">
              {{ $t(`setPassword.setPasswordDescription${isClub ? 'Club' : ''}`) }}
            </p>
            <form :data-test-id="elementTest + '-form'" @submit.prevent="setPassword">
              <InputField
                v-model="password"
                :input-type="'password'"
                name="password"
                :field-title="$t('fields.password')"
                required
                :placeholder-value="$t('fields.password')"
                :element-test="elementTest + '-password'"
              >
                <template #iconLeft>
                  <div class="icon-password u-bg-steel-grey" :data-test-id="elementTest + '-password-icon'" />
                </template>
              </InputField>
              <InputField
                v-model="confirmPassword"
                :input-type="'password'"
                name="password"
                :field-title="$t('fields.passwordConfirmation')"
                required
                :placeholder-value="$t('fields.passwordConfirmation')"
                :element-test="elementTest + '-password-confirmation'"
              >
                <template #iconLeft>
                  <div
                    class="icon-password u-bg-steel-grey"
                    :data-test-id="elementTest + '-password-confirmation-icon'"
                  />
                </template>
              </InputField>
              <div v-if="isLoading" class="loader" :data-test-id="elementTest + '-loader'" />
              <p v-if="error" class="error" :data-test-id="elementTest + '-error'">
                {{ error }}
              </p>
              <MainButton
                :label="$t('actions.savePassword')"
                :data-test-id="elementTest + '-save-password'"
                tag="button"
                class-variant="full-width"
                :disabled="!passwordsAreValid"
              />
            </form>
          </div>
        </div>
        <ImageLazy
          src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/login-persons.webp"
          :alt="$t('brandName')"
          :title="$t('brandName')"
          :data-test-id="elementTest + '-illu'"
          class="login-main-illu"
        />
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/mixins/sp-api-mixin'
import Club from '@/mixins/club-mixin'
import i18nMx from '@/mixins/i18n-mixin'

import MainButton from '@/components/Buttons/MainButton/MainButton'
import InputField from '@/components/InputField/InputField'

export default {
  name: 'PasswordSetForm',
  components: {
    MainButton,
    InputField,
  },
  mixins: [API, Club, i18nMx],
  props: {
    compact: { type: Boolean, default: false },
    elementTest: { type: String, default: '' },
  },
  emits: ['go-back', 'confirm-password-set'],
  data() {
    return {
      password: null,
      confirmPassword: null,
    }
  },
  computed: {
    forgotPassword() {
      return this.$store.getters['activation/forgotPassword']
    },
    passwordsAreValid() {
      return (
        this.password !== null &&
        this.password !== '' &&
        this.confirmPassword !== null &&
        this.confirmPassword !== '' &&
        this.password === this.confirmPassword
      )
    },
  },
  methods: {
    setPassword() {
      const { $api, $currentUser } = useNuxtApp()
      // Set user's password
      if (!this.isLoading && this.passwordsAreValid) {
        this.isLoading = true
        this.error = null
        this.spRequest({
          req: $api().user.setPassword($currentUser().user, this.password),
        }).then(confirmed => {
          if (confirmed) {
            if (this.compact) {
              this.$emit('confirm-password-set', true)
            } else {
              const localePath = useLocalePath()
              this.$router.push(localePath('index'))
            }
          } else {
            const { country } = useCurrentLocale()
            this.error = this.$t('backendError.unknown', { phone: country?.phoneNumber })
          }
        })
      }
    },
  },
}
</script>
